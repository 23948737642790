<template>
  <div class="container">
    <v-sheet color="grey lighten-3">
      <v-container>
        <v-row>
          <v-col md="4" v-for="i in [0, 1, 2]" :key="i">
            <v-card class="mx-auto" max-width="344" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">COUPONS</div>
                  <v-list-item-title class="text-h5 mb-1">
                    {{
                      CouponTypes[i] == "qr_code"
                        ? "QR CODE"
                        : CouponTypes[i] == "Link"
                        ? "LINK"
                        : "PIN"
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon> mdi-cash </v-icon>
                    Value:
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar size="80" color="var(--main)">
                  <v-icon x-large color="white">
                    {{
                      CouponTypes[i] == "qr_code"
                        ? "mdi-qrcode"
                        : CouponTypes[i] == "Link"
                        ? "mdi-link"
                        : "mdi-dialpad"
                    }}
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>
              <v-card-actions>
                <v-btn elevation="0" color="var(--main)" class="text-light">
                  {{ CouponValue(CouponTypes[i]) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <v-col md="4" v-for="post in posts" :key="post.id">
          <v-card
            class="mx-auto mb-2"
            width="320"
            :max-height="post.content.length >= 100 ? 400 : 350"
          >
            <v-img
              v-if="!post.featured"
              src="../../assets/svg/subtle-prism.svg"
              class="text-light"
            >
              <v-card-title>
                <v-icon large left> mdi-subtitles-outline </v-icon>
                <span class="text-h6 font-weight-light">{{ post.title }}</span>
              </v-card-title>

              <v-card-text class="text-h6 font-weight-bold">
                {{ TrimPostContent(post.content) }}
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow text-light">
                  <v-list-item-avatar color="grey">
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="post.writer.photo"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      post.writer.firstname
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-row align="center" justify="end">
                    <v-btn icon @click="SetFocusPost(post)">
                      <v-icon class="mr-1 text-light"> mdi-comment-eye </v-icon>
                    </v-btn>
                    <span class="subheading mr-2"></span>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-img>

            <v-img v-if="post.featured" :src="post.featured" class="text-light">
              <v-card-title>
                <v-icon large left> mdi-subtitles-outline </v-icon>
                <span class="text-h6 font-weight-light">{{ post.title }}</span>
              </v-card-title>

              <v-card-text class="text-h6 font-weight-bold">
                {{ TrimPostContent(post.content) }}
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow text-light">
                  <v-list-item-avatar color="grey">
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="post.writer.photo"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      post.writer.firstname
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-row align="center" justify="end">
                    <v-btn icon @click="SetFocusPost(post)">
                      <v-icon class="mr-1 text-light"> mdi-comment-eye </v-icon>
                    </v-btn>
                    <span class="subheading mr-2"></span>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",
  data: () => ({
    CouponTypes: ["Link", "qr_code", "Pin"],
    coupons: null,
    Posts: null,
  }),
  methods: {
    ...mapActions(["LoadCoupons"]),
    CouponValue(type) {
      let value = 0.0;
      if (this.coupons !== null) {
        this.coupons.forEach((coupon) => {
          if (type == coupon.type && coupon.used == false) {
            value = parseFloat(value + parseFloat(coupon.value));
          }
        });
      }
      return value;
    },
    loadCoupons() {
      this.coupons = this.getCoupons;
    },
    loadPosts() {
      this.Posts = this.posts;
    },
    TrimPostContent(content) {
      return content.length >= 100 ? content.substring(0, 99) : content;
    },
  },
  created() {
    this.LoadCoupons(this.uid);
    this.loadCoupons();
    this.loadPosts();
  },
  computed: {
    ...mapGetters({
      getCoupons: "getCoupons",
      uid: "getUid",
      comments: "getPostComments",
      posts: "getPosts",
    }),
  },
};
</script>
<style scoped>
:root {
  --main: #f06d34;
  --accent: #222832;
}
</style>
